import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"
import ProductCard from "../components/ProductCard"
import styled from "styled-components"

import S17PAImage from "../images/s-series/timberline-s-series-17-pa-12g-15g-tanks.jpg"
import S20DImage from "../images/s-series/timberline-s-series-20-disk-pa-12g-15g-tanks.jpg"
import S24CImage from "../images/s-series/timberline-s-series-24-grout-12g-15g-tanks.jpg"
import S20OImage from "../images/s-series/timberline-s-series-20-orbital-12g-15g-tanks.jpg"
import S17Image from "../images/s-series/timberline-s-series-17-tr-12g-15g-tanks.jpg"
import S20DiskTractionImage from "../images/s-series/timberline-s-series-20-tr-12g-15g-tanks.jpg"

import IndexSection3 from "../components/IndexSection3"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const SPageStyles = styled.div`
  #product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #available-text {
    position: fixed;
    right: -120px;
    top: 90px;
    padding: 2rem;
    font-size: 2em;
    width: 600px;
    text-align: center;
    background-color: red;
    color: white;
    z-index: 2;
    box-shadow: 5px 10px 10px #888888;

    -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari prior 9.0 */
    transform: rotate(30deg); /* Standard syntax */
    transition-duration: 0.5s;

    @media (max-width: 1570px) {
      right: -200px;
      font-size: 1.3em;
    }

    @media (max-width: 1260px) {
      position: static;
      /* right: 0; */
      /* height: 20px; */

      font-size: 1.5em;
      /* top: 150px; */
      margin: 0;
      padding: 0.7rem;
      -ms-transform: rotate(360deg); /* IE 9 */
      -webkit-transform: rotate(360deg); /* Safari prior 9.0 */
      transform: rotate(360deg);
      width: 100%;
    }
  }
`

const SseriesPage = () => {
  return (
    <>
      <SEO title="Small Walk Behind Floor Scrubbers | Timberline Cleaning Equipment" />
      <Helmet>
        <title>
          Small Walk Behind Floor Scrubbers | Timberline Cleaning Equipment
        </title>
        <description>
          Built to scrub and built to last, the S-Series is the right size for
          many applications.
        </description>
      </Helmet>
      <MainLayout>
        <PageHeading
          title="Small Walk Behind Floor Scrubbers"
          subHeading="S-Series"
          description="Built to scrub and built to last, the S-Series is the right size for many applications."
          path="17 - 20in (43 - 51cm)"
          tank="Sol: 12gal (45l) Rec: 15gal (56l)"
          time="Up to 2.5 hours"
          title1="Cleaning Path:"
          title2="Tank Capacity:"
          title3="Run Time:"
        />
        <SPageStyles>
          {/* <p id="available-text">Available Fall 2020</p> */}
          <div id="product-container">
            <ProductCard
              image={S17PAImage}
              title="S17 Disk Pad Assist"
              description="Cleaning Path - 17 in / 430 mm"
              link="/products/s17-disk-scrubber-pad-assist"
            />
            <ProductCard
              image={S24CImage}
              title="S24 Cylindrical "
              description="Cleaning Path - 24 in / 610 mm"
              link="/products/s24-cylindrical-scrubber"
            />
            <ProductCard
              image={S20OImage}
              title="S20 Orbital"
              description="Cleaning Path - 20 in / 510 mm"
              link="/products/s20-orbital-scrubber"
            />
            <ProductCard
              image={S20DImage}
              title="S20 Disk Pad Assist"
              description="Cleaning Path - 20 in / 510 mm"
              link="/products/s20-disk-scrubber-pad-assist"
            />
            <ProductCard
              image={S17Image}
              title="S17 Disk"
              description="Cleaning Path - 17 in / 430 mm"
              link="/products/s17-disk-scrubber"
            />
            <ProductCard
              image={S20DiskTractionImage}
              title="S20 Disk"
              description="Cleaning Path - 20 in / 510 mm"
              link="/products/s20-disk-scrubber"
            />
          </div>
        </SPageStyles>
        <IndexSection3 title="Request a Free Demo at your Facility" />
      </MainLayout>
    </>
  )
}

export default SseriesPage
